.topicview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1C1C1C;
  color: #ffffff;
  -webkit-user-select: text;
     -moz-user-select: text;
          user-select: text;
}
.topicview nav {
  position: fixed;
  z-index: 1;
  top: 0;
  padding-top: 15px;
  width: 90%;
  display: flex;
  border-end-start-radius: 15px;
  border-end-end-radius: 15px;
  justify-content: space-between;
  background: linear-gradient(to bottom, rgba(28, 28, 28, 0.8), rgba(28, 28, 28, 0.1));
}
.topicview nav img {
  width: 50px;
  height: 50px;
  -o-object-fit: contain;
     object-fit: contain;
}
.topicview nav button {
  background: none;
  border: none;
}
.topicview nav button img {
  width: 50px;
  height: 50px;
  -o-object-fit: contain;
     object-fit: contain;
}

.icon {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 20px;
  font-weight: 200;
}

.icon-title {
  padding-bottom: 10px;
}

.resumo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  padding-top: 25px;
}

.resumo-tag {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}
.resumo-tag .resumo-titulo {
  font-weight: 800;
  font-size: 30px;
}
.resumo-tag .resumo-subtitulo {
  font-weight: 200;
  font-size: 20px;
}

.resumo-texto {
  padding: 3vw;
  background-color: #ffffff;
  color: #1C1C1C;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 25px;
  font-weight: 400;
  border-radius: 20px;
  width: 80vw;
  height: 60vh;
  overflow-y: auto;
  text-align: center;
}

.cards {
  height: 100vh;
  width: 100vw;
  background-color: rgba(39, 39, 39, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 25px;
  text-align: center;
}

.cards-tag {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}
.cards-tag .cards-titulo {
  font-weight: 800;
  font-size: 30px;
}
.cards-tag .cards-subtitulo {
  font-weight: 200;
  font-size: 20px;
}

.cards-fundo {
  padding: 3vw;
  background-color: #ffffff;
  color: rgb(28, 28, 28);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 25px;
  font-weight: 400;
  border-radius: 20px;
  width: 80vw;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: justify-content 0.5s;
}

.cards-top {
  position: relative;
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: end;
  opacity: 80%;
  transition: all 0.5s;
}

.cards-top:hover {
  opacity: 100%;
}

.cards-top-text {
  font-weight: 200;
  transition: opacity 0.5s;
}

.cards-top-icon img {
  width: 30px;
}

.cards-center {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cards-bottom {
  font-size: 15px;
  cursor: pointer;
}

.cards-bottom-regular {
  color: #727272;
}

.cards-bottom-selected {
  color: #1C1C1C;
}

.card {
  width: 300px;
  height: 150px;
  padding: 50px;
  background-color: #faf9f9;
  color: #1C1C1C;
  border-radius: 25px;
  box-shadow: 1px 1px 1px 1px rgb(84, 84, 84);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  transition: transform 0.6s;
}
.card:active {
  transform: rotateY(180deg);
}

.turned {
  background-color: #1C1C1C;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  -webkit-user-select: text;
     -moz-user-select: text;
          user-select: text;
}

.cards-arrows {
  color: #727272;
  font-size: 96px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
}

.quiz {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  padding-top: 25px;
}

.quiz-tag {
  display: flex;
  flex-direction: column;
  align-items: end;
  padding-bottom: 20px;
}
.quiz-tag .quiz-titulo {
  font-weight: 800;
  font-size: 30px;
}
.quiz-tag .quiz-subtitulo {
  font-weight: 200;
  font-size: 20px;
}

.quiz-fundo {
  padding: 3vw;
  background-color: #ffffff;
  color: #1C1C1C;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 25px;
  font-weight: 400;
  border-radius: 20px;
  width: 80vw;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.quiz-question {
  font-weight: 600;
  padding-bottom: 10px;
  text-align: center;
  cursor: pointer;
}

.quiz-option {
  text-align: center;
  padding: 20px;
  margin: 20px;
  background-color: #faf9f9;
  width: 70vw;
  box-shadow: 1px 1px 1px 1px rgb(84, 84, 84);
  border-radius: 20px;
  font-size: 18px;
  cursor: pointer;
}

.quiz-explanation {
  position: absolute;
  z-index: 2;
  width: 80vw;
  height: 60vh;
  padding: 3vw;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: rgba(28, 28, 28, 0.8);
  text-align: center;
}
.quiz-explanation img {
  width: 30vw;
  height: 30vh;
  -o-object-fit: contain;
     object-fit: contain;
}

.quiz-explanation-text {
  font-weight: 600;
}

.quiz-end {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.quiz-score {
  font-size: 60px;
  font-weight: 600;
}

.quiz-review-title {
  font-weight: 400;
}

.quiz-review-text {
  font-weight: 200;
}

.quiz-review-date {
  font-size: 15px;
  font-weight: 200;
}

@media (max-width: 812px) {
  .resumo {
    height: 95vh;
    padding-top: 0;
    padding-bottom: 0;
  }
  .cards {
    height: 95vh;
    padding-top: 0;
    padding-bottom: 0;
  }
  .quiz {
    height: 95vh;
    padding-top: 0;
    padding-bottom: 0;
  }
  .resumo-tag {
    padding-bottom: 20px;
  }
  .resumo-tag .resumo-titulo {
    font-size: 22px;
  }
  .resumo-tag .resumo-subtitulo {
    font-weight: 200;
    font-size: 18px;
  }
  .resumo-texto {
    padding: 3.5vw;
    background-color: #ffffff;
    color: #1C1C1C;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 25px;
    font-weight: 400;
    border-radius: 20px;
    width: 80vw;
    height: 60vh;
    overflow-y: auto;
    text-align: center;
  }
  .cards-tag {
    padding-bottom: 20px;
  }
  .cards-tag .cards-titulo {
    font-weight: 800;
    font-size: 22px;
  }
  .cards-tag .cards-subtitulo {
    font-weight: 200;
    font-size: 18px;
  }
  .cards-fundo {
    padding: 3.5vw;
    font-size: 20px;
  }
  .cards-top {
    gap: 15px;
  }
  .card {
    width: 100px;
    height: 200px;
    font-size: 25px;
  }
  .turned {
    background-color: #1C1C1C;
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    -webkit-user-select: text;
       -moz-user-select: text;
            user-select: text;
  }
  .cards-arrows {
    color: #727272;
    font-size: 55px;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    cursor: pointer;
  }
  .quiz-tag {
    display: flex;
    flex-direction: column;
    align-items: end;
    padding-bottom: 20px;
  }
  .quiz-tag .quiz-titulo {
    font-weight: 800;
    font-size: 22px;
  }
  .quiz-tag .quiz-subtitulo {
    font-weight: 200;
    font-size: 18px;
  }
  .quiz-fundo {
    padding: 3.5vw;
  }
  .quiz-question {
    font-size: 20px;
  }
  .quiz-option {
    width: 65vw;
    font-size: 15px;
  }
  .quiz-explanation {
    padding: 3.5vw;
  }
  .quiz-explanation img {
    width: 300px;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .quiz-review-text {
    font-size: 20px;
    margin: 0px 25px;
  }
}/*# sourceMappingURL=Topic.css.map */