.revisai-home {
    position: relative;
    width: 100%;
    height: 100vh; 
    overflow: hidden; 
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .revisai-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }
  }
  
  .revisai-content {
    background-color: rgba(29, 29, 29, 0.95);
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    color: white; // Cor do texto para contraste com o fundo
    padding: 20px; // Espaçamento interno para evitar que o conteúdo encoste nas bordas
  
    nav {
        position: fixed;
        top: 0;
        padding-top: 15px;
        width: 90%;
        display: flex;
        justify-content: space-between;
      img {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }

      button {
        background: none;
        border: none;
        img {
            width: 50px;
            height: 50px;
            object-fit: contain;
        }
      }
    }
  
    .center-block {
        margin: 20px;
      .title {
        font-size: 3.5vw;
        padding-bottom: 5px;
        font-weight: 300;
      }

      .home-topic {
        text-decoration: none;
        font-weight: 800;
        color: white;
        white-space: nowrap;
        cursor: pointer;
    }
  
      .subtitle {
        font-size: 1.5vw; 
        margin-bottom: 5px;
      }
    }
  
    input {
      padding: 10px;
      font-size: 1rem;
      margin-bottom: 20px;
      border: none;
      border-radius: 10px;
      height: 10%;
      max-height: 50px;
      width: 80%;
      max-width: 400px;
    }
  
    .submit {
      background: white;
      border: none;
      padding: 8px 40px;
      border-radius: 22px;
      transition: all 0.5s;
      cursor: pointer;
      img {
        width: 130px;
        height: 36px;
        object-fit: contain;
      }

      &:hover {
        transform: translateY(-1.5px);
      }

      &:active {
        transform: translateY(1.5px);
      }
    }

    input[type=text] {
        background-color: white;
        background-image: url('../public/logopreto.png');
        background-size: 52px;
        background-position: 10px 10px;
        background-repeat: no-repeat;
        padding-left: 70px;
        font-size: 30px;
    }
  }
  
.menu-button {
  cursor: pointer;
}

.historybar {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  overflow-y: auto;
  background-color: #2b2b2b;
  color: #fff;
  padding: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: end;
  text-align: end;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.historybar.open {
  transform: translateX(0); /* Movendo a barra lateral para a posição visível */
}


.historybar-titulo {
  font-weight: 600;
  font-size: 40px;
}

.historybar-separador {
  width: 100%;
  min-height: 1px;
  background-color: #fff;
  margin-top: 15px;
}

.historybar-filler {
  width: 100%;
  padding-top: 80px;
  opacity: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 200;
}

.historybar-section {
  display: flex;
  flex-direction: column;
}

.historybar-section-title {
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 10px;
  margin-top: 20px;
}

.historybar-link {
  color: #fff;
  padding-top: 5px;
  font-size: 25px;
  text-decoration: none;
  font-weight: 200;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

@media (max-width: 812px) {
  .revisai-home {
    position: relative;
    width: 100%;
    height: 100vh; 
    display: flex;
    justify-content: center;
    align-items: center;
  }

  nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
  }

  .icon {
    display: flex;
  }

  .icon-title {
    display: none;
  }

  .center-block {
    .title {
      font-size: 35px !important;
    }
    .subtitle {
      font-size: 15px !important;
    }
  }

  .submit {
    margin-bottom: 75px;
  }
}