.error-page {
    width: 100vw;
    height: 100vh;
    background-color: #1C1C1C;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 250px;
        object-fit: contain;
    }
}

.error-title {
    font-weight: 600;
    font-size: 25px;
}

.error-text {
    padding-top: 10px;
    font-weight: 200;
    font-size: 15px;
    width: 40%;
    text-align: center;
    padding-bottom: 10px;
}

.error-voltar {
    font-family: 400;
    color: #fff;
    padding: 8px;
    border: 1px solid #fff;
    border-radius: 10px;
    text-decoration: none;
}

@media (max-width: 700px) {
    .error-page {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .error-voltar {
        margin-bottom: 100px;
    }
}